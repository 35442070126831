import { useSetRecoilState } from "recoil";
import WalletLight from "../../../../assets/images/WalletLight.svg";
import WalletDark from "../../../../assets/images/WalletDark.svg";
import "./insufficientFunds.scss";
import { useCallback } from "react";
import { DepositModalOpenState, WithdrawModalOpenState } from "@states/wallet";
import useDarkMode from "use-dark-mode";
import { useNavigate } from "react-router-dom";

type InsufficientFundsProps = {
  subHeading?: string;
  fromAction?: boolean;
  setVisibleModify?: (visible: boolean) => void;
  transactionType?: string;
  showButton?: boolean;
};

const InsufficientFunds: React.FC<InsufficientFundsProps> = ({
  subHeading,
  fromAction,
  setVisibleModify,
  transactionType,
  showButton = true
}: any) => {
  const setIsDepositModalOpen = useSetRecoilState(DepositModalOpenState);
  const setIsWithdrawModalOpen = useSetRecoilState(WithdrawModalOpenState);
  const darkMode = useDarkMode(false);
  const navigate = useNavigate();

  const handleClickDeposit = useCallback(() => {
    if (transactionType === "deposit") {
      // Close the modal
      setIsDepositModalOpen(false);
    } else {
      // Open the deposit modal
      if (fromAction) {
        setVisibleModify(false);
        navigate("/wallet");
      }
      setIsWithdrawModalOpen(false);
      setIsDepositModalOpen(true);
    }
  }, [
    fromAction,
    navigate,
    setIsDepositModalOpen,
    setIsWithdrawModalOpen,
    setVisibleModify,
    transactionType,
  ]);

  return (
    <div className="InsufficientFunds">
      <div className="InsufficientFunds__img">
        <img src={darkMode.value ? WalletDark : WalletLight} alt="" />
      </div>
      <div className="InsufficientFunds__texts">
        <div className="InsufficientFunds__texts__heading">
          Insufficient fund available
        </div>
        <div className="InsufficientFunds__texts__subHeading">
          {subHeading || ""}
        </div>
      </div>
      {!!showButton && <button className="InsufficientFunds__btn" onClick={handleClickDeposit}>
        {transactionType === "deposit" ? "Close" : "Deposit"}
      </button>}
    </div>
  );
};

export default InsufficientFunds;
