import { useEffect } from "react";
import { UAParser } from "ua-parser-js";

export const MpcMobileRedirect = () => {
  const parser = new UAParser(window.navigator.userAgent);
  const result = parser.getResult();
  const deviceOS = result?.os?.name;

  useEffect(() => {
    let fallbackURL = "";
    let deepLink = "liquidity://"; 

    if (deviceOS === "Android") {
      fallbackURL = "https://play.google.com/store/apps/details?id=com.liquidity";
    } else {
      fallbackURL = "https://apps.apple.com/in/app/testflight/id899247664";
    }

    let isAppOpened = false; 

    const beforeUnloadHandler = () => {
      isAppOpened = true; 
    };

    window.addEventListener("blur", beforeUnloadHandler); 

    const deepLinkTimeout = setTimeout(() => {
      window.location.href = deepLink;
    }, 100);

    const fallbackTimeout = setTimeout(() => {
      if (!isAppOpened) {
        window.location.href = fallbackURL;
      }
    }, 2500);

    return () => {
      window.removeEventListener("blur", beforeUnloadHandler);
      clearTimeout(deepLinkTimeout);
      clearTimeout(fallbackTimeout);
    };
  }, [deviceOS]);

  return <div></div>;
};

export default MpcMobileRedirect;

