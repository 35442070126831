import { lazy, useCallback, useEffect, useMemo, useState,  MouseEvent, MouseEventHandler } from "react";
import cn from "classnames";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { SideDrawer } from "components/drawer";
import { Currency } from "views/exchange/components";
import { Settings, User } from "./component";
import Theme from "../Theme";
import {
  ActiveCurrencyState,
  LoginPhoneNumberState,
  userSelectedCurrencyState,
  userPersonalDetails,
  userAllDetailsState,
  TriggerUserApi,
  InfoState,
  userProfileState,
  UsersLoadingState,
  linkedDeviceState,
  linkedDeviceRefresh,
  allCoOwnerState,
  coOwnerApiCall,
  userDetailsState,
} from "states";
import { ROUTES } from "routes/constants";

import Liquid from "../../assets/images/Liquidity_Blue_Logo.png";
// import Liquid from "../../images/Liquidity_Blue_Logo.png"
import styles from "./Header.module.sass";
import { useCookie, useLocalStorage, useNetwork } from "hooks";
import { APIS, USER_DETAILS } from "constant";
import { Image } from "@storybook";
import { UseTransaction } from "views/MyWallet/store/hooks";
import UAParser from "ua-parser-js";
import { useCalculateWindowSize } from "@hooks/calculate-window-size";
import Notification from "@views/Notification/Notification";
import NotificationBell from "@components/NotificationBell/NotificationBell";
import { NotificationDataState } from "@views/Notification/store/state";

const ChangeUser = lazy(() =>
  import("views/account/components").then(({ ChangeUser }) => ({
    default: ChangeUser,
  }))
);

let navigation = [
  {
    title: "Marketplace",
    url: "/exchange",
  },
  // {
  //   title: "News",
  //   url: "/news",
  // },
  {
    title: "Orders",
    url: ROUTES.ORDERSPAGE,
  },
  {
    title: "Wallet",
    url: ROUTES.WALLET,
  },
  {
    title: "Reports",
    url: "/reports",
  },
  // {
  //   title: "Auction",
  //   url: "/auction",
  // },
];

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setUserDetails = useSetRecoilState(userAllDetailsState);
  const setInfoData = useSetRecoilState(InfoState);
  const isRefreshDeviceList = useRecoilValue(linkedDeviceRefresh);

  const activeCurrency = useRecoilValue(ActiveCurrencyState);
  const notificationData = useRecoilValue<any>(NotificationDataState);
  const setLoginDetailUser = useSetRecoilState(LoginPhoneNumberState);
  const setSelectedCurrency = useSetRecoilState(userSelectedCurrencyState);
  const [personalData, setPersonalData] = useRecoilState(userPersonalDetails);
  const setProfilePic = useSetRecoilState(userProfileState);
  const setUserLoading = useSetRecoilState(UsersLoadingState);
  const setDevicelist = useSetRecoilState(linkedDeviceState);
  const [isDevicePostApiSuccsess, setIsDevicePostApiSuccsess] = useState(false);
  const coOwnerList = useRecoilValue(allCoOwnerState);
  const iscoOwnerApiCall = useRecoilValue(coOwnerApiCall);
  const userData = useRecoilValue(userDetailsState);

  const { set: setLocalStorage } = useLocalStorage();

  const [visibleNav, setVisibleNav] = useState(false);
  const [visible, setVisible] = useState(false);
  const props = { visible, setVisible };

  const { post: userDeviceData } = useNetwork();
  const { get: getInfo, data: infoData } = useNetwork();
  const { getFortressAccountInfo } = UseTransaction();
  const { get: getCookieData } = useCookie();
  const [ipAddress, setIPAddress] = useState("");
  const { get: getlinkedDevice } = useNetwork();
  const { get: getLocalStorage } = useLocalStorage();
  const { width } = useCalculateWindowSize(0);

  const isOwner = useMemo(() => {
    const primaryUser: any =
      getLocalStorage("dropdown")?.["isPrimary" as any] ?? "";
    if (iscoOwnerApiCall) {
      if (coOwnerList?.length > 0 && !primaryUser) {
        return false;
      }
      return true;
    }
  }, [coOwnerList, iscoOwnerApiCall]);

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIPAddress(data.ip))
      .catch((error) => console.log(error));
  }, []);

  let parser = new UAParser(window.navigator.userAgent);
  let result = parser.getResult();

  useEffect(() => {
    getInfo(APIS.Info);
  }, []);

  useEffect(() => {
    if (infoData?.data?.stellar) {
      setInfoData(infoData?.data?.stellar);
    }
  }, [infoData]);

  useEffect(() => {
    getFortressAccountInfo();
  }, [getFortressAccountInfo]);
  useEffect(() => {
    setUserLoading(true);
    if (userData?.data) {
      const {
        settings = "",
        phone,
        countryCode,
        firstName,
        lastName,
        issuerName,
        email,
        onboardingData,
        id,
        isAdmin,
        isBlockChat,
        isVerifiedEmail,
        profileImage,
        isTradeEnabled,
        isTransactionEnabled,
        polygonId,
        accounts,
        walletAddress,
        watchlistId,
        customerId,
        dvp,
      } = userData.data;
      setSelectedCurrency(settings.currency);
      setProfilePic(profileImage);
      setLoginDetailUser({
        phone,
        countryCode,
      });
      const data = {
        ...userData.data,
        firstName,
        lastName,
        issuerName,
        email,
        phone,
        currency: settings?.currency?.code,
        countryCode,
        kyc: onboardingData?.kycStatus,
        kyb: onboardingData?.kybStatus,
        address: onboardingData?.fullAddress,
        amlStatus: onboardingData?.amlStatus,
        accreditationStatus: onboardingData?.accreditationStatus,
        id,
        isAdmin,
        isVerifiedEmail,
        profileImage,
        isTradeEnabled,
        isTransactionEnabled,
        polygonId,
        isBlockChat,
        accounts,
        walletAddress,
        watchlistId,
        customerId,
        onboardingData,
        dvp,
      };
      setPersonalData(data);
      setLocalStorage(USER_DETAILS, data);
      setUserLoading(false);
      setUserDetails(userData?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.data]);

  useEffect(() => {
    const userDetails = getCookieData("userDetails");

    if (isOwner && ipAddress) {
      const payload = {
        deviceIp: ipAddress,
        device: result?.device?.model,
        deviceOS: result?.os?.name,
        deviceBrowser: result?.browser?.name,
        deviceType: result?.device?.type ?? "desktop",
        deviceToken: userDetails?.token,
      };
      userDeviceData(APIS.UsersDeviceLogin, payload).then((resp) =>
        setIsDevicePostApiSuccsess(true)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCookieData, userDeviceData, ipAddress, isOwner]);

  const getAllLinkedDevice = () => {
    getlinkedDevice(APIS.UsersDeviceLogin).then((resp) => {
      if (resp?.data) {
        setDevicelist(resp?.data);
      }
    });
  };

  useEffect(() => {
    isDevicePostApiSuccsess && getAllLinkedDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDevicePostApiSuccsess, isRefreshDeviceList]);

  useEffect(() => {
    if (width >= 1024) {
      setVisible(false);
    }
  }, [width]);

  const body = <Currency />;
  const notification = <Notification />;

  const logo = (
    <div className={styles.logo1}>
      <img src={Liquid} alt="" className={styles.brand_logo} />
    </div>
  );

  const handleOnClick = useCallback(() => {
    return setVisible(true);
  }, []);

  const handleNavigate = useCallback((url: string) => {
    setVisibleNav(false);
    navigate(url);
  }, []);

  const handleLogoClick: MouseEventHandler<HTMLDivElement> = (event) => {
    const target = event.target as HTMLDivElement;
    if (target.classList.contains(styles.brand_logo)) {
      const logoClickDestination = "/exchange";
      navigate(logoClickDestination);
    }
  };

  // const handleChange = useCallback(() => { }, [])

  return (
    <>
      <div className={styles.tablet_wrap}>
        <button className={styles.drawer_open_btn} onClick={handleOnClick}>
          <i className="ri-menu-unfold-line" />
        </button>
        <header className={cn(styles.header)}>
          <div className={cn("excahngecontainer", styles.container)}>
            {visible && <SideDrawer {...props} body={body} logo={logo} />}
            <div className={styles.logo}>
              <div className={styles.logo1} onClick={handleLogoClick}>
                {/* <img src={Liquid} alt="" className={styles.brand_logo} />
                 */}
                <Image
                  className={styles.brand_logo}
                  fileName={`images/Liquidity_Blue_Logo.png`}
                />
              </div>
            </div>
            <div
              className={styles.wrapper}
              // style={{ width: "100%" }}
            >
              <div
                style={{ width: "100%" }}
                className={cn(styles.wrap, { [styles.visible]: visibleNav })}
              >
                <nav className={styles.nav}>
                  {navigation.map(
                    (x, index) => (
                      // x.dropdown ? (
                      //   <Dropdown
                      //     className={styles.dropdown}
                      //     key={index}
                      //     item={x}
                      //     setValue={setVisibleNav}
                      //   />
                      // ) : (
                      // <NavLink
                      //   className={styles.item}
                      //   // activeClassName={styles.active}
                      //   onClick={() => handleNavigate(x.url)}
                      //   to={x.url}
                      //   key={index}
                      // >
                      <div
                        onClick={() => handleNavigate(x.url)}
                        className={cn(styles.navItem, {
                          [styles.activeTab]: location.pathname.includes(x.url),
                        })}
                        key={index}
                      >
                        {x.title}
                      </div>
                      // </NavLink>
                    )
                    // )
                  )}
                </nav>
                {/* <NavLink
                  className={cn("button-stroke", styles.button)}
                  // activeClassName={styles.active}
                  to="/wallet"
                  onClick={() => setVisibleNav(false)}
                >
                  Wallet
                </NavLink> */}
                {/* <div className={styles.btns}>
              <Link
                className={cn("button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-up"
              >
                Sign Up
              </Link>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                activeClassName={styles.active}
                to="/sign-in"
              >
                Login
              </Link>
            </div> */}
              </div>
              <div className={styles.containSettings}>
                {/* <div className={styles.userDropdown}> */}
                <ChangeUser />
                <Settings className={styles.settings} />
                {/* <Settings className={styles.settings} />
          <div className={styles.control}>
            <NavLink
              className={styles.activity}
              // activeClassName={styles.active}
              to="/activity"
            >
                              <Notifications className={styles.notifications} />
                            </NavLink>
*/}

                <div className={styles.user_container}>
                  <NotificationBell />
                  <Theme className={styles.theme} icon small={undefined} />
                  {/* <button
                    className={cn("button-stroke button-small", styles.button)}
                    //  activeClassName={styles.active}
                    // to="/wallet"
                    onClick={() => handleNavigate(WALLET)}
                  >
                    Wallet
                  </button> */}
                  <User className={styles.user} />
                </div>
              </div>
            </div>
            {/* <div className={styles.btns}>
            <Link
              className={cn("button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-up"
              onClick={() => setVisibleNav(false)}
            >
              Sign Up
            </Link>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              activeClassName={styles.active}
              to="/sign-in"
              onClick={() => setVisibleNav(false)}
            >
              Login
            </Link>
          </div> */}
            {/* <button
              className={cn(styles.burger, { [styles.active]: visibleNav })}
              onClick={() => setVisibleNav(!visibleNav)}
            ></button> */}
          </div>
          {/* </div> */}
        </header>
      </div>
    </>
  );
};
