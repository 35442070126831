import React from "react";
import useDarkMode from "use-dark-mode";

import maintenanceLight from "../../assets/images/maintenanceLight.svg";
import maintenanceDark from "../../assets/images/maintenanceDark.svg";

import "./maintenance.scss"; // Import the CSS file

export const Maintenance: React.FC = () => {
  const darkMode = useDarkMode(false);

  return (
    <div className="maintenance-container">
      <div className="icon-container">
        <img src={darkMode.value ? maintenanceDark : maintenanceLight} alt="" />
      </div>
      <h2>We are under maintenance</h2>
      <p>
        We're currently performing maintenance. We'll be back soon. Thank you
        for your patience!
        <br />
        Please check back in a little while.
      </p>
      <p>
        If you need to get in touch, please contact us at{" "}
        <a href="mailto:email@example.com">{`{email}`}</a>.
      </p>
    </div>
  );
};
