import { USER_DETAILS } from "constant";
import type {
  IPhoneNumber,
  ILoginUser,
  IUserAllDetails,
  IComplianceData,
  IPersonalDetails,
  ISessionType,
  IPhoneMinMax,
} from ".";

import { atom } from "recoil";

let user = { phone: "", token: "", countryCode: "", isLoggedIn: false };

export const userLockedState = atom<boolean>({
  key: "user-lock",
  default: false,
});

export const accountLockedType = atom<any>({
  key: "user-locked",
  default: {},
});

export const SendOTPState = atom<boolean>({
  key: "send-otp",
  default: false,
});
export const LoginPhoneNumberState = atom<IPhoneNumber>({
  key: "login-phone-state",
  default: {
    phone: "",
    countryCode: "+1",
  },
});

export const LoginPhoneNumberMeta = atom<IPhoneMinMax>({
  key: "login-phone-meta",
  default: {
    minLength: 9,
    maxLength: 13,
  },
});
export const LoginUserState = atom<ILoginUser>({
  key: "login-user-data",
  default: {
    phone: user?.phone ?? "",
    countryCode: user?.countryCode ?? "",
    token: user?.token ?? "",
    isLoggedIn: user?.isLoggedIn,
  },
});

export const TempLoginUserState = atom<ILoginUser>({
  key: "temp-login-user-data",
  default: {
    phone: user?.phone ?? "",
    countryCode: user?.countryCode ?? "",
    token: user?.token ?? "",
    isLoggedIn: user?.isLoggedIn,
  },
});

export const holdLoginState = atom<boolean>({
  key: "login-hold-state",
  default: true,
});

export const userAllDetailsState = atom<IUserAllDetails>({
  key: "user-all-details-state",
  default: {
    countryCode: "",
    phone: "",
  },
});

export const isAdminState = atom<any>({
  key: "is-admin",
  default: false,
});

export const complianceDataState = atom<IComplianceData>({
  key: "compliance-data-state",
  default: {
    onboardingData: {},
    id: "",
    email: "",
    countryCode: "",
    phone: "",
    firstName: "",
    lastName: "",
  },
});

export const openComplianceState = atom<boolean>({
  key: "open-compliance-state",
  default: false,
});

export const reOpenKycSignupModalState = atom<boolean>({
  key: "re-open-kyc-signup-modal-state",
  default: true,
});

export const isStartVerificationState = atom<boolean>({
  key: "is-start-verification-state",
  default: false,
});

export const isStartVerificationNewState = atom<boolean>({
  key: "is-start-verification-new-state",
  default: false,
});

export const isIframeState = atom<boolean>({
  key: "is-iframe-state",
  default: false,
});
export const reinitiateKycState = atom<boolean>({
  key: "reinitiateKycState",
  default: false,
});

export const signUpInfoState = atom<any>({
  key: "sign-up-info-state",
  default: {
    firstName: "",
    lastName: "",
    email: "",
  },
});

export const userDetailsState = atom<any>({
  key: "user-details",
  default: {},
});

export const userPersonalDetails = atom<IPersonalDetails>({
  key: "user-personal-details",
  default: JSON.parse((localStorage.getItem(USER_DETAILS) as string) || "{}"),
});

export const UsersLoadingState = atom<boolean>({
  key: "users-loading-state",
  default: false,
});

export const TriggerUserApi = atom<boolean>({
  key: "trigger-user-api",
  default: false,
});

export const loadingState = atom<boolean>({
  key: "loading-state",
  default: false,
});

export const darkThemeState = atom<boolean>({
  key: "theme-state",
  default: true,
});

export const userProfileState = atom<string>({
  key: "user-profile-state",
  default: "",
});

export const imageUplaodingState = atom<boolean>({
  key: "image-uplaoding-state",
  default: false,
});

export const linkedDeviceState = atom<any>({
  key: "linked-device-state",
  default: [],
});


export const MobileVerificationState = atom<string>({
  key: "mobile-verification-id",
  default: "",
});

export const linkedDeviceRefresh = atom<boolean>({
  key: "linked-device-refresh",
  default: false,
});


export const maintenanceState = atom<any>({
  key: "maintenance-state",
  default: {
    isAppMaintenance: false,
    isPaymentMaintenance: false,
    isTreadingMaintenance: false
  },
});